import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { navigationKeys } from '.';
import { fetchNavigation } from './connector';

const useNavigation = () =>
  useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => fetchNavigation(),
    queryKey: navigationKeys.navigation(),
  });

export { useNavigation };
